<template>
  <div class="problemList">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left">
          <el-card>
            <search-filter
              ref="searchFilter"
              :searchName="'搜索题单'"
              :keywordOptions="{
                placeholder: '请输入题单名称或题单编号',
                prop: 'keyword',
                autoName: 'forum',
                autocomplete: true,
              }"
              :searchOptions="[
                {
                  name: 'el-form-item',
                  options: {
                    label: '难度',
                    prop: 'diff_id',
                    type: 'radio-group',
                    data: { name: 'question_list', keys: 'diff_id' },
                    defaultValue: '全部',
                  },
                },
                {
                  name: 'el-form-item',
                  options: {
                    label: '来源',
                    prop: 'from_id',
                    type: 'radio-group',
                    data: { name: 'question_list', keys: 'from_id' },
                    defaultValue: '全部',
                  },
                },
                {
                  name: 'el-form-item',
                  options: {
                    label: '讲题链接',
                    prop: 'link',
                    type: 'radio-group',
                    data: { name: 'question_list', keys: 'link' },
                    defaultValue: '全部',
                  },
                },
              ]"
              v-model="where"
              :total="operationTable.total"
              @search="currentChange(1)"
            ></search-filter>

            <div class="operate">
              <el-button
                type="primary"
                size="mini"
                style="height: 30px"
                :plain="params.match_type === 2"
                v-if="userInfo.type === 1 && params.match_type != 2"
                @click="
                  setWherePrams({
                    match_type: 2,
                  })
                "
              >
                我做过的题单
              </el-button>
              <el-button
                type="primary"
                size="mini"
                style="height: 30px"
                v-if="userInfo.type === 1 && params.match_type == 2"
                @click="
                  setWherePrams({
                    match_type: (params.match_type = null),
                  })
                "
              >
                全部题单
              </el-button>
              <!-- <el-button type="primary" size="mini" :plain="params.match_type === 1" v-if="userInfo.type === 2" @click="setWherePrams({match_type:params.match_type === 1?null:1})">
              我创建的题单
            </el-button> -->
              <el-button
                type="primary"
                size="mini"
                style="height: 30px"
                :plain="params.match_type === 1"
                v-if="userInfo.type === 2"
                @click="showMyList"
              >
                我管理的题单
              </el-button>
              <el-button
                type="primary"
                size="mini"
                style="height: 30px"
                @click="goNewProblemList"
                v-if="userInfo.type === 2"
              >
                新建题单
              </el-button>
            </div>
          </el-card>

          <div class="table-content" v-loading="problemLoading">
            <el-table
              v-if="!showMine"
              :data="operationTable.tableData"
              tooltip-effect="dark"
              @row-click="rowDblclick"
              style="width: 100%"
              height="800"
              :row-style="{ height: '0' }"
              :cell-style="{ padding: '6px' }"
              :header-cell-style="{
                height: '50',
                padding: '4px',
                background: '#F9F9F9',
              }"
            >
              <el-table-column label="编号" prop="id" width="80"  align="center">
              </el-table-column>
              <el-table-column label="题单" width="360" align="center">
                <template slot-scope="scope">
                  <span v-html="scope.row.title"></span>
                </template>
              </el-table-column>
              <el-table-column label="难度"  width="80" align="center">
                <template slot-scope="scope">
                  <div
                    class="btn"
                    :style="{ background: scope.row.diff_color }"
                    v-if="scope.row.diff_title"
                  >
                    {{ scope.row.diff_title }}
                  </div>
                  <div v-else>--</div>
                </template>
              </el-table-column>
              <el-table-column label="来源" align="center">
                <template slot-scope="scope">
                  <div
                    class="btn"
                    :style="{ background: scope.row.from_color }"
                    v-if="scope.row.from_title"
                  >
                    {{ scope.row.from_title }}
                  </div>
                  <div v-else>--</div>
                </template>
              </el-table-column>
              <!-- <el-table-column label="难度" width="80" align="center">
                <template slot-scope="scope">
                  <div
                    class="btn"
                    :style="{ background: scope.row.diff_color }"
                    v-if="scope.row.diff_title"
                  >
                    {{ scope.row.diff_title }}
                  </div>
                  <div v-else>--</div>
                </template>
              </el-table-column> -->
              <el-table-column label="题目数" width="80" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.question_num">
                    {{ scope.row.question_num }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="可见范围"
                show-overflow-tooltip
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.group_names }}
                </template>
              </el-table-column>
              <el-table-column label="提交人数" width="100"  align="center">
                <template slot-scope="scope">
                  {{ scope.row.respondent_num }}
                </template>
              </el-table-column>
              <el-table-column label="创建人" align="center">
                <template slot-scope="scope">
                  {{ scope.row.user_nickname }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="180"
                v-if="[1, 2].indexOf(userInfo.type) > -1"
              >
                <div
                  style="
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                  "
                  @click.stop="isClick = false"
                  slot-scope="scope"
                >
                <el-button
                v-if="scope.row.from_title=='真题' && scope.row.course_id>0"
                    type="text"
                    size="mini"
                    @click.stop="topicLink(scope.row)"
                    >讲题链接</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="newCopy(scope.row)"
                    >拷贝创建</el-button
                  >
                
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="download(scope.row)"
                    >下载</el-button
                  >
                </div>
              </el-table-column>
            </el-table>

            <!-- <ProblemListTable
              v-if="!showMine"
              :operation="operationTable"
              :is-click="isClick"
              :rowDblclickPath="'/problemList/student/problemListDetails'"
            >
              <el-table-column
                label="操作"
                align="center"
                width="180"
                v-if="[1, 2].indexOf(userInfo.type) > -1"
              >
                <div
                  style="
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                  "
                  @click.stop="isClick = false"
                  slot-scope="scope"
                >
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="newCopy(scope.row)"
                    >拷贝创建</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="download(scope.row)"
                    >下载</el-button
                  >
                </div>
              </el-table-column>
            </ProblemListTable> -->
            <!-- 我创建的题单 -->
            <div v-if="showMine">
              <div class="table" style="margin-top: 20px" v-loading="loading">
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :row-key="(row) => row.id"
                >
                  <el-table-column label="编号" prop="id" align="center">
                  </el-table-column>
                  <el-table-column
                    label="来源－创建人－题单名称"
                    align="center"
                    width="260"
                  >
                    <template slot-scope="scope">
                      <span class="ProblemList-name">{{
                        scope.row.fromStr +
                        "-" +
                        scope.row.user_nickname +
                        "-" +
                        scope.row.title
                      }}</span></template
                    >
                  </el-table-column>
                  <!-- <el-table-column label="时间" align="center">
                    <template slot-scope="scope">{{
                      scope.row.time_str
                    }}</template>
                  </el-table-column> -->
                  <el-table-column label="难度" align="center">
                    <template slot-scope="scope">{{
                      scope.row.diffStr
                    }}</template>
                  </el-table-column>
                  <el-table-column label="创建时间" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.create_date }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        size="small"
                        @click="
                          $handleRoute(
                            { list_id: scope.row.id },
                            'teachingQuestListInfo'
                          )
                        "
                        >查看</el-button
                      >
                      <el-button
                        type="text"
                        :disabled="!$isPowers('questListEdit')"
                        size="small"
                        @click="
                          $handleRoute(
                            { list_id: scope.row.id },
                            'teachingQuestListEdit'
                          )
                        "
                        >编辑</el-button
                      >
                      <el-button
                        type="text"
                        :disabled="!$isPowers('questListCensus')"
                        size="small"
                        @click="
                          $handleRoute(
                            { list_id: scope.row.id },
                            'teachingQuestListCensus'
                          )
                        "
                        >答题统计</el-button
                      >
                      <el-button
                        type="text"
                        size="small"
                        @click="
                          $handleRoute(
                            { list_id: scope.row.id },
                            'teachingQuestListQuestion'
                          )
                        "
                        >题目管理</el-button
                      >
                      <!-- <el-button type="text" size="small" @click="handleRouteManage">题目管理</el-button> -->
                      <el-button
                        :disabled="!$isPowers('questListDel')"
                        @click="
                          $handleConfirm(
                            '你确定删除此题单吗？',
                            '/xapi/question.quest_list/delete',
                            { id: scope.row.id },
                            () => {
                              getList();
                            }
                          )
                        "
                        type="text"
                        size="small"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="pagination" style="margin-top: 20px">
                <el-pagination
                  background
                  layout="total, sizes, prev, pager, next"
                  :total="total"
                  @current-change="currentChange"
                  :page-size="pageSize"
                  :current-page="page"
                >
                </el-pagination>
              </div>

              <!-- <com-table
                ref="table"
                :data="tableData"
                :columns="columns"
                :total.sync="total"
                :auto-load="false"
                :edit-method="handleEditChange"
              >
                <template slot="action" slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="
                      $handleRoute(
                        { list_id: scope.row.id },
                        'teachingQuestListInfo'
                      )
                    "
                    >查看</el-button
                  >
                  <el-button
                    type="text"
                    :disabled="!$isPowers('questListEdit')"
                    size="small"
                    @click="
                      $handleRoute(
                        { list_id: scope.row.id },
                        'teachingQuestListEdit'
                      )
                    "
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    :disabled="!$isPowers('questListCensus')"
                    size="small"
                    @click="
                      $handleRoute(
                        { list_id: scope.row.id },
                        'teachingQuestListCensus'
                      )
                    "
                    >答题统计</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="
                      $handleRoute(
                        { list_id: scope.row.id },
                        'teachingQuestListQuestion'
                      )
                    "
                    >题目管理</el-button
                  >
                  <el-button
                    :disabled="!$isPowers('questListDel')"
                    @click="
                      $handleConfirm(
                        '你确定删除此题单吗？',
                        '/xapi/question.quest_list/delete',
                        { id: scope.row.id },
                        () => {
                          $refs.table.reset();
                        }
                      )
                    "
                    type="text"
                    size="small"
                    >删除</el-button
                  >
                </template>
              </com-table> -->
            </div>

            <div class="pagination">
              <el-pagination
                background
                layout="total, sizes, prev, pager, next"
                :total="operationTable.total"
                @current-change="currentChange"
                :page-size="pageSize"
                :current-page="page"
                :hide-on-single-page="true"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- <div class="content-right">
          <Advertising></Advertising>
          <know-points class="margin"></know-points>
          <HotRecommend class="margin"></HotRecommend>
        </div> -->
      </div>
    </div>
    <diy-dialog
      class="order-payment-dialog"
      :bodyStyle="{ 'padding-top': 0 }"
      ref="orderPayment"
      width="500px"
      :showFooter="false"
    >
      <order-sure
        type="base"
        @paySuccess="successPay"
        :detail="{ ...currentData, showResult: false }"
        :order_id="currentData.id"
      ></order-sure>
    </diy-dialog>
  </div>
</template>

<script>
import Advertising from "@/components/Advertising/Advertising.vue";
import HotRecommend from "@/views/api/com/hotRecommend";
import ProblemListTable from "@/components/ProblemListTable/ProblemListTable.vue";
import { question_list, detailList } from "@/api/problemList.js";
import { orderCreate } from "@/api/common.js";
import { copyTD } from "@/api/topic.js";
import SearchFilter from "@/components/com/SearchFilter";
import OrderSure from "@/views/api/com/orderSure";
import KnowPoints from "@/views/api/com/knowPoints";
export default {
  components: {
    KnowPoints,
    SearchFilter,
    Advertising,
    HotRecommend,
    ProblemListTable,
    OrderSure,
  },
  data() {
    return {
      where: {},
      params: {},
      isClick: true,
      showMine: false,
      operationTable: {
        label: "题单名称",
        isSelection: false,
        time_str: true,
        tableData: [],
        total: 0,
      },
      page: 1,
      pageSize: 50,
      total: 0,
      loading: false,
      tableData: [],
      // columns:[
      //   {prop:'id',label:'编号',width:'80' , align:'center'},
      //   {prop:'title',label:'<span><font style="color:red;">来源</font> - <font style="color:green;">创建人</font> - 题单名称</span>',template:(scope)=>{
      //       // return '<font style="color:red;">'+(scope.row.fromStr || '')+'</font> - <font style="color:green;">'+scope.row.user_name+'</font> - '+this.$jquery("<font>"+scope.row.title+"</font>").text();
      //       return '<font style="color:red;">'+(scope.row.fromStr || '')+'</font> - <font style="color:green;">'+`${scope.row.user_nickname?scope.row.user_nickname:scope.row.user_name}`+'</font> - '+this.$jquery("<font>"+scope.row.title+"</font>").text();
      //     },minWidth:220 , align:'left'},
      //   {prop:'time_str',label:'时间',width:'120' , align:'center'},
      //   {prop:'diffStr',label:'难度',width:'120' , align:'center'},
      //   {prop:'create_date',label:'创建时间',width:'160' , align:'center'},
      //   {prop:'recommend',label:'是否推荐',width:'110' , slot:'switch' , align:'center',options:{activeValue:1,inactiveValue:0} ,callback(props){props.disabled = !_this.$isPowers('questListEdit')}},
      //   {prop:'valid',label:'是否有效',width:'110' , slot:'switch' , align:'center' ,callback(props){props.disabled = !_this.$isPowers('questListEdit')}},
      //   {fixed:'right',label:'操作',width:'240' , slot:'action' , align:'center'},
      // ],
      // tableData: {
      //   url:'/xapi/question.quest_list/myList'
      // },
      total: 0,

      //分页相关
      page: 1,
      page_size: 50,
      problemLoading: false,
      currentData: {},
      payType: "",
    };
  },
  watch: {
    isClick(v) {
      if (!v) {
        this.$nextTick(() => {
          this.isClick = true;
        });
      }
    },
  },
  created() {},
  methods: {
    // 获取学生列表
    getList() {
      // page: 1,
      // pageSize: 50,
      // total: 0,
      this.loading = true;

      // this.$http.get(`/xapi/question.quest_list/myList?page=`+this.page+'&pageSize='+this.pageSize).then((res)=>{
      this.$http
        .get(
          `/xapi/question.question/myList?page=` +
            this.page +
            "&pageSize=" +
            this.pageSize
        )
        .then((res) => {
          console.log(res, "liebbbbb");
          this.loading = false;

          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 分页
    currentChange(e) {
      this.page = e;
      this.getList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    showMyList() {
      this.$handleRoute("/problemList/myProblemList");
      // this.$handleRoute('/problemList/myProblemList1')
      //   this.showMine = !this.showMine;
      // this.getList();
    },

    handleRouteManage() {},

    handleEditChange(v, ov, { row, column }) {
      this.$http
        .request({
          url: "/xapi/question.quest_list/updateField",
          params: {
            field: column.prop,
            id: row.id,
            value: v,
          },
        })
        .catch(() => {
          row[column.prop] = ov;
        });
    },

    routeUpdate() {
      this.where = { ...this.$route.query, ...this.$route.query.params };
    },
    newCopy(e) {
      this.currentData = e;
      this.payType = "copy";
      this.orderCreate(e, this.successPay);
    },
    // 讲题链接
    topicLink(e){
      this.$handleRoute({ course_id: e.course_id }, 'courseDetail')
    },
    handlerCopyTD(e) {
      this.problemLoading = true;
      copyTD({ question_list_id: e.id })
        .then((res) => {
          console.log(res.data.id);
          detailList({ id: res.data.id })
            .then((res) => {
              this.$store.state.topicType = {
                name: "编辑题单",
                detail: res.data,
              };
              this.$router.push({
                path: "/problemListOperate",
                query: { id: e.id },
              });
            })
            .finally((error) => {
              this.problemLoading = false;
            });
        })
        .catch(() => {
          this.problemLoading = false;
        });
    },
    questionList(param = {}) {
      this.problemLoading = true;

      question_list({
        ...this.where,
        ...this.params,
        ...(param || {}),
        page: this.page,
        page_size: this.page_size,
      })
        .then(({ data }) => {
          this.operationTable.total = data.total;
          this.operationTable.tableData = data.data;
          this.$store.commit("SET", { key: "headerValue.flag", value: false });
        })
        .finally(() => {
          this.problemLoading = false;
          this.tag_id = "";
        });
    },
    currentChange(e) {
      this.page = e;
      this.questionList();
    },
    setWherePrams(params, isOverride = false) {
      params || {};
      this.page = 1;
      if (isOverride) {
        this.params = params;
      } else {
        this.params = { ...this.params, ...params };
      }
      this.questionList();
    },
    goNewProblemList() {
      if (this.userInfo.type + "" === "2") {
        this.$handleRoute("teachingQuestListCreate");
      } else {
        this.$store.state.topicType = {
          name: "新建题单",
          detail: {},
        };
        this.$router.push({ path: "/problemListOperate" });
      }
    },
    orderCreate(e, callback) {
      if (!(this.userInfo.id > 0)) {
        this.$store.state.lodinShow = true;
      } else {
        if (e.is_buy + "" === "0") {
          //创建订单
          orderCreate({
            type: 3,
            id: e.id,
          }).then((res) => {
            //已支付
            if (res.data.is_pay + "" === "1") {
              this.$message({
                message: "支付成功",
                type: "success",
              });
              callback && callback();
            } else {
              this.currentData = res.data.order;
              this.$refs.orderPayment.visibled(true);
            }
          });
        } else if (e.is_buy + "" === "1") {
          callback && callback();
        }
      }
    },
    //下载
    download(e) {
      this.currentData = e;
      this.payType = "download";
      this.orderCreate(e, this.successPay);
    },
    rowDblclick(e) {
      console.log(e, "eeeeeee");
      this.$handleRoute(
        { id: e.id, is_buy: e.is_buy },
        "/problemList/student/problemListDetails",
        "_blank"
      );
    },
    //支付成功
    successPay() {
      this.problemLoading = false;
      if (this.payType === "download") {
        this.$tool.fileResponse(this.currentData.down_url);
      } else {
        this.handlerCopyTD(this.currentData);
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-card__body {
    position: relative;
    // display: flex;
    // justify-content: space-between;
    // // align-items: center;
    // .classify {
    //   flex: 1;
    // }
    .operate{
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
.btn {
    background: #2d93f8;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    padding: 0px 10px;
  }
.box {
  display: flex;
  justify-content: space-between;

  .content-left {
    // width: 70%;
    width: 100%;
    .operate {
      // margin: 2.2% 0;
      // margin-top: 40px;
      // margin-bottom: 0.5rem;
      display: flex;
      justify-content: flex-end;
    }
    .table-content {
      padding: 1rem;
      margin-top: 20px;
      background-color: #fff;
      .pagination {
        text-align: center;
        margin: 2rem 0;
      }
    }
  }
  .content-right {
    width: 27%;
    .margin {
      margin-top: 40px;
    }
  }
}
</style>